import { Component, OnInit } from '@angular/core';
import { BatchHisto } from 'src/app/shared/models/batch-histo/batch-histo.model';
import { BatchHistoService } from 'src/app/shared/services/batch-histo/batch-histo.service';

@Component({
	selector: 'app-test-component',
	templateUrl: './test-component.component.html',
	styleUrls: ['./test-component.component.sass']
})
export class TestComponentComponent implements OnInit {

	public lastBatchInfos: BatchHisto

	constructor(private batchHistoService: BatchHistoService) { }

	ngOnInit(): void {
		this.getLastBatchHistoInfos();
	}

	private getLastBatchHistoInfos(){
		this.batchHistoService.getLastBatchInfos().subscribe(batchHisto => this.lastBatchInfos = batchHisto);
	}
}
