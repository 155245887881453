export class BatchHisto {
    public batch_id: number
    public batch_datetime: Date
    public nb_added_stats: number
    public nb_added_zones: number
    public nb_added_sources: number

    constructor(batchHisto?: BatchHisto) {
        this.batch_id = batchHisto && batchHisto.batch_id || 0
        this.batch_datetime = batchHisto && batchHisto.batch_datetime || new Date(0);
        this.nb_added_stats = batchHisto && batchHisto.nb_added_stats || 0
        this.nb_added_zones = batchHisto && batchHisto.nb_added_zones || 0
        this.nb_added_sources = batchHisto && batchHisto.nb_added_sources || 0
    }
}
