import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BatchHisto } from '../../models/batch-histo/batch-histo.model';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from '../message/message.service';

@Injectable({providedIn: 'root'})

export class BatchHistoService {

	batchHistoUrl: string = 'https://covid.thierryg.com/batch-histo'
	lastBatchInfo: BatchHisto;

	constructor(
		private http: HttpClient,
		private messageService: MessageService) { }

	private log(message: string) {
		this.messageService.add(`HeroService: ${message}`);
	}

	public getLastBatchInfos(): Observable<BatchHisto> {
		return this.http.get<BatchHisto>(this.batchHistoUrl).pipe(
			catchError(this.handleError<BatchHisto>('getLastBatchInfos', new BatchHisto()))
		)
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
		  this.log(`${operation} failed: ${error.message}`);
		  return of(result as T);
		};
	  }
}
